import { useState, useEffect } from 'react'

import { Link } from '@remix-run/react'

import { Container, Logo } from '~/components'
import { IconArrowRight } from '~/components/ui/icons/arrow-right'

export const TopHeader = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  useEffect(() => {
    if (isMenuOpen) {
      document.body.style.overflow = 'hidden'
    } else {
      document.body.style.overflow = 'auto'
    }
    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [isMenuOpen])

  return (
    <header
      aria-label="header"
      className="fixed left-0 top-0 z-50 h-14 w-full bg-white shadow-sm"
    >
      <Container className="!mx-0 h-[58px] w-full !max-w-none px-0">
        <div className="flex h-full items-center justify-between px-4 sm:pl-6 lg:pl-8">
          <Link className="flex h-full items-center gap-x-4" to="/">
            <Logo />
            <h2
              aria-label="header-logo-title"
              className="text-xs font-bold text-brand lg:text-sm"
            >
              シェアオフィス
              <br />
              コワーキングスペース検索サイト
            </h2>
          </Link>

          <button
            aria-expanded={isMenuOpen}
            aria-label={isMenuOpen ? 'メニューを閉じる' : 'メニューを開く'}
            className="relative z-50 ml-auto flex h-[50px] w-[50px] items-center justify-center rounded-full hover:bg-gray-100"
            onClick={() => setIsMenuOpen(!isMenuOpen)}
          >
            {!isMenuOpen ? (
              <div className="mt-1 flex flex-col items-center">
                <div className="flex flex-col gap-[4px]">
                  <span className="h-[2px] w-5 bg-[#1E3031]" />
                  <span className="h-[2px] w-5 bg-[#1E3031]" />
                  <span className="h-[2px] w-5 bg-[#1E3031]" />
                </div>
                <span className="mt-0.3 text-[10px] font-semibold text-[#09909E]">
                  メニュー
                </span>
              </div>
            ) : (
              <div className="flex flex-col items-center">
                <span className="text-2xl text-[#1E3031]">×</span>
                <span className="-mt-2 text-[10px] font-semibold text-[#09909E]">
                  閉じる
                </span>
              </div>
            )}
          </button>

          {/* Overlay */}
          {isMenuOpen && (
            <div
              aria-label="メニューを閉じる"
              className="fixed inset-0 top-[56px] z-40 bg-black/50 transition-opacity"
              onClick={() => setIsMenuOpen(false)}
              onKeyDown={(e) => e.key === 'Escape' && setIsMenuOpen(false)}
              role="button"
              tabIndex={0}
            />
          )}

          {/* Drawer */}
          <div
            className={`fixed right-0 top-[56px] z-40 h-[calc(100vh-58px)] w-[340px] transform overflow-y-auto bg-white shadow-lg transition-transform duration-300 ease-in-out ${
              isMenuOpen ? 'translate-x-0' : 'translate-x-full'
            }`}
          >
            <nav className="flex h-full flex-col justify-between">
              <div>
                <h3 className="bg-[#EAF7F8] px-4 py-2 text-sm font-medium">
                  オフィスを探す
                </h3>
                <ul className="divide-y divide-gray-100">
                  <li>
                    <Link
                      className="flex items-center justify-between bg-white px-4 py-3 text-base hover:bg-gray-50"
                      to="/"
                    >
                      トップページ
                      <IconArrowRight
                        className="text-gray-400"
                        color="gray"
                        height="16"
                        width="16"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="flex items-center justify-between bg-white px-4 py-3 text-base hover:bg-gray-50"
                      to="/map"
                    >
                      マップで探す
                      <IconArrowRight
                        className="text-gray-400"
                        color="gray"
                        height="16"
                        width="16"
                      />
                    </Link>
                  </li>
                </ul>

                <div className="h-[1px] bg-gray-100" />
                <h3 className="bg-[#EAF7F8] px-4 py-2 text-sm font-medium">
                  オフィス情報を見る
                </h3>
                <ul className="divide-y divide-gray-100">
                  <li>
                    <a
                      className="flex items-center justify-between bg-white px-4 py-3 text-base hover:bg-gray-50"
                      href="https://media.officemap.jp/"
                      rel="noopener noreferrer"
                      target="_blank"
                    >
                      オフィスマップメディア
                      <IconArrowRight
                        className="text-gray-400"
                        color="gray"
                        height="16"
                        width="16"
                      />
                    </a>
                  </li>
                </ul>
              </div>

              <div className="pb-8">
                <h3 className="px-4 py-2 text-sm font-medium">
                  officeMAPに関する情報
                </h3>
                <ul className="flex flex-wrap items-center gap-x-4 px-4 py-2">
                  <li>
                    <Link
                      className="text-sm text-[#0066CC] hover:underline"
                      rel="noopener noreferrer"
                      target="_blank"
                      to="https://media.officemap.jp/about/"
                    >
                      オフィスマップとは
                    </Link>
                  </li>
                  <li className="h-4 border-r border-gray-300" />
                  <li>
                    <Link
                      className="text-sm text-[#0066CC] hover:underline"
                      rel="noopener noreferrer"
                      target="_blank"
                      to="/faq"
                    >
                      よくある質問
                    </Link>
                  </li>
                  <li className="h-4 border-r border-gray-300" />
                  <li>
                    <Link
                      className="text-sm text-[#0066CC] hover:underline"
                      rel="noopener noreferrer"
                      target="_blank"
                      to="/contact"
                    >
                      お問い合わせ
                    </Link>
                  </li>
                  <li className="h-4 border-r border-gray-300" />
                  <li>
                    <Link
                      className="text-sm text-[#0066CC] hover:underline"
                      rel="noopener noreferrer"
                      target="_blank"
                      to="/privacy-policy"
                    >
                      プライバシーポリシー
                    </Link>
                  </li>
                  <li className="h-4 border-r border-gray-300" />
                  <li>
                    <Link
                      className="text-sm text-[#0066CC] hover:underline"
                      rel="noopener noreferrer"
                      target="_blank"
                      to="/terms"
                    >
                      利用規約
                    </Link>
                  </li>
                  <li className="h-4 border-r border-gray-300" />
                  <li>
                    <Link
                      className="text-sm text-[#0066CC] hover:underline"
                      rel="noopener noreferrer"
                      target="_blank"
                      to="https://trancereal.co.jp"
                    >
                      運営会社
                    </Link>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </Container>
    </header>
  )
}
